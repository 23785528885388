import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | RoadCrafters Sürüş Akademisine
			</title>
			<meta name={"description"} content={"Geleceği Bugünden Sürün!"} />
			<meta property={"og:title"} content={"Ana Sayfa | RoadCrafters Sürüş Akademisine"} />
			<meta property={"og:description"} content={"Geleceği Bugünden Sürün!"} />
			<meta property={"og:image"} content={"https://wovado.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://wovado.com/img/3381772.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://wovado.com/img/3381772.png"} />
			<meta name={"msapplication-TileImage"} content={"https://wovado.com/img/3381772.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" sm-padding="40px 0" background="--color-primary" quarkly-title="About-8">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="100%" />
			<Box
				width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				padding="90px 64px 90px 64px"
				background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(110, 37, 189, 0.51) 0%),rgba(0, 0, 0, 0) url(https://wovado.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box"
				lg-width="100%"
				sm-padding="32px 32px 32px 32px"
				lg-padding="48px 64px 90px 64px"
				md-padding="48px 48px 90px 48px"
			>
				<Text margin="0px 0px 24px 0px" font="--headline1" color="--light" sm-font="--headline2">
					RoadCrafters
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					font="--base"
					color="--light"
					max-width="400px"
					sm-max-width="none"
				>
					Hoş geldiniz, geleceğin sürücüleri! RoadCrafters Sürüş Akademisi'nde, kendinden emin ve yetenekli bir sürücü olma yolunda size rehberlik etmekten heyecan duyuyoruz. İster ilk kez direksiyon başına geçiyor olun, ister sürüş becerilerinizi tazelemek istiyor olun, doğru yeri buldunuz. Güler yüzlü, profesyonel eğitmenlerimiz ve yenilikçi öğretim yöntemlerimiz, herkes için sıcak ve verimli bir öğrenme ortamı sağlar.
				</Text>
				<Link
					href="/contacts"
					color="--dark"
					text-decoration-line="initial"
					padding="9px 24px 10px 24px"
					background="--color-light"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="0.5px"
					border-radius="6px"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				width="30%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				position="relative"
				justify-content="center"
				lg-width="100%"
				lg-justify-content="flex-start"
				background="rgba(27, 38, 40, 0.6)"
			>
				<Image
					src="https://wovado.com/img/2.jpg"
					display="block"
					max-height="80%"
					position="absolute"
					left="-200px"
					width="500px"
					height="500px"
					object-fit="cover"
					lg-width="100%"
					lg-height="auto"
					lg-max-height="600px"
					sm-max-height="400px"
				/>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="20px 0"
			sm-padding="40px 0"
		>
			<Text font="--headline2" margin="10px 0 0 0">
			Hakkımızda
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			RoadCrafters Sürüş Akademisi, sürüş başarısına giden yolda güvenilir ortağınızdır. En son eğitim tekniklerini uygulamalı pratikle birleştiren kapsamlı bir öğrenme deneyimi sunmaktan gurur duyuyoruz. Kurslarımız bireysel ihtiyaçlarınıza uyacak şekilde tasarlanır, direksiyon başında güven kazanmanıza ve güvenli sürüş sanatında ustalaşmanıza yardımcı olur. Son teknoloji araçlarımız ve teknoloji destekli eğitimimizle sürüş derslerini keyifli ve etkili hale getiriyoruz.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-primary"
			quarkly-title="Advantages/Features-20"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Neden RoadCrafters'ı Seçmelisiniz?
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--light"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Kişiselleştirilmiş Öğrenme Yolları:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Her öğrenci benzersizdir ve öğretim yaklaşımımız da öyle. Kişiye özel dersler, kendi hızınızda ilerlemenizi sağlar.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--darkL2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								İleri Düzey Güvenlik Eğitimi:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Güvenlik bizim en önemli önceliğimizdir. Yolda güvende olmanızı sağlayacak en yeni defansif sürüş tekniklerini öğrenin.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Esnek Programlama:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Hayatın yoğun olduğunu biliyoruz. Bu nedenle yaşam tarzınıza uyacak esnek zamanlama seçenekleri sunuyoruz.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Rekabetçi Fiyatlandırma:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Kaliteden ödün vermeden sürüş eğitimine yaptığınız yatırım için en iyi değeri elde edin.

							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					sm-height="300px"
					lg-height="auto"
					lg-width="100%"
				>
					<Image
						height="589px"
						box-shadow="0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)"
						src="https://wovado.com/img/3.jpg"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						sm-height="300px"
						object-fit="cover"
						lg-display="block"
						lg-height="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});